<template>
   <section class="login-content">
         <div class="container h-100">
            <div class="row align-items-center justify-content-center h-100">
               <div class="col-md-5">
                  <div class="card p-3">
                     <div class="card-body">
                        <div class="mys-logo">
                           <img src="@/assets/images/logo-v2.png" class="img-fluid  rounded-normal  darkmode-logo" width="140px" alt="logo">
                        </div>
                        <h3 class="mb-3 font-weight-bold text-center">Sign In</h3>
                        <p class="text-center text-secondary mb-4">Log in to your account to continue</p>
                       
                        <form action="POST" @submit.prevent="loginUser">
                           <div class="row">
                              <div class="col-lg-12">
                                 <div class="form-group">
                                    <label class="text-secondary">Email</label>
                                    <input class="form-control" type="email" placeholder="Enter Email" v-model="authDetails.email">
                                 </div>
                              </div>
                              <div class="col-lg-12 mt-2">
                                 <div class="form-group">
                                     <div class="d-flex justify-content-between align-items-center">
                                         <label class="text-secondary">Password</label>
                                     </div>                                    
                                    <input class="form-control" type="password" placeholder="Enter Password" v-model="authDetails.password">
                                 </div>
                              </div>
                              <div class="form-group col-lg-12 mt-2" style="align-items: end">
                                 <a href="#" class="float-right" @click="onForgotPassword">Forgot Password? </a>
                              </div>                           
                           </div>
                           <button type="submit" class="btn btn-block mt-2 login-btn font-weight-bold">Login</button>
                           
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
   name:'SignIn',
   data(){
      return{
         authDetails: {
            email: '',
            password: ''
         },
         url:process.env.VUE_APP_URL + 'password/reset/',
      }
   },
    methods: {
    ...mapActions(['login']),
      loginUser: function(){
         this.login({vm : this,authDetails : this.authDetails})
       
      },
      onForgotPassword(){
         location.href = this.url;
      }
   },
   
   computed : {
        ...mapGetters({
            image:'img'
        })
   },

}
</script>
<style>
.login-btn{
   font-size: 18px;
   background-color: #EC008C;
   color:#ffff;
}

.login-btn:hover{
   background-color: #EC008C;
   color:#ffff;
}

.toast-style {
  /* font-size: 1.2rem;
  padding: 1rem; */
  padding: 10px;
  border-radius: 3px;
}

.b-toast-danger.b-toast-solid .toast.toast-box {
  background-color: red;
  color: white;
 
}

.b-toast-danger.b-toast-solid .toast.toast-box .toast-header button {
  color: white;
}

.mys-logo{
	display: flex;
	justify-content: center;
	margin-bottom: 1.5rem;
	margin-top: 1rem;
}
</style>